import Cookies from "js-cookie";

var COOKIE_POMPEII_USER = "POMPEII_USER";
var COOKIE_POMPEII_FULLNAME = "POMPEII_USER_FULLNAME";
var COOKIE_POMPEII_DATABASE = "POMPEII_DATABASE";
var COOKIE_POMPEII_RESOURCE = "POMPEII_RESOURCE";
var COOKIE_POMPEII_RESOURCE_NAME = "POMPEII_RESOURCE_NAME";
var COOKIE_POMPEII_ENVIRONMENT = "POMPEII_SELECTED_ENVIRONMENT";
var COOKIE_POMPEII_SYSTEMCODE = "POMPEII_SYSTEMCODE";
var COOKIE_POMPEII_ACCESSTOKEN = "POMPEII_ACCESSTOKEN";
var COOKIE_POMPEII_SESSIONCOOKIES = "POMPEII_SESSIONCOOKIES";
var COOKIE_POMPEII_NOTIFICATIONFACILITY = "POMPEII_NOTIFICATION_FACILITY";
var COOKIE_BUILDVERSION = "BuildVersion";
var COOKIE_CHECK_TIMER = "POMPEII_CHECK_TIMER";
var COOKIE_FOTO_ENABLED = "POMPEII_FOTO_ENABLED";
var COOKIE_SCHEDULING_ENABLED = "POMPEII_SCHEDULING_ENABLED";
var COOKIE_HELP_AUTH = "PPH_AUTH";
var COOKIE_IE11WARNINGMESSAGE = "IE11WARNINGMESSAGE";
var COOKIE_IPL_ENABLED = "POMPEII_IPL_ENABLED";
var COOKIE_CAN_FAX = "POMPEII_CAN_FAX";
var COOKIE_FAX_TOP_MARGIN = "POMPEII_FAX_TOP_MARGIN";
var COOKIE_NEW_BILLING_REVIEW = "POMPEII_NEW_BILLING_REVIEW";
var COOKIE_FLOWSHEETS_ENABLED = "POMPEII_FLOWSHEETS_ENABLED";
var COOKIE_NURSING_WORKFLOWS_ENABLED = "POMPEII_NURSING_WORKFLOWS_ENABLED";
var COOKIE_ORDERS_ENABLED = "POMPEII_ORDERS_ENABLED";
var COOKIE_ORDERS_WORKFLOWS_ENABLED = "POMPEII_ORDERS_WORKFLOWS_ENABLED";
var COOKIE_INTERVENTIONS_ENABLED = "POMPEII_INTERVENTIONS_ENABLED";
var COOKIE_TIMEENTRY_ENABLED = "COOKIE_TIMEENTRY_ENABLED";
var COOKIE_ACCESS_DATA_BLOCKING_ENABLED = "POMPEII_ACCESS_DATA_BLOCKING_ENABLED";
var COOKIE_LASTACTIVITYTIME = "POMPEII_LASTACTIVITYTIME";
var COOKIE_DICTIONARIES_ENABLEDREM = "POMPEII_DICTIONARIES_ENABLEDREM";
var COOKIE_DOCUMENTATION_ENABLESENDTOTHIRDPARTY = "POMPEII_DOCUMENTATION_ENABLESENDTOTHIRDPARTY";
var COOKIE_DOCUMENTATION_ENABLESENDTOTHIRDPARTYMESSAGE = "POMPEII_DOCUMENTATION_ENABLESENDTOTHIRDPARTYMESSAGE";
var COOKIE_SMART_TEXT_ENABLED = "POMPEII_COOKIE_SMART_TEXT_ENABLED";

var POMPEII_DATE_FORMAT = "MM/DD/YYYY";
var POMPEII_DATE_TIME_FORMAT = "MM/DD/YYYY hh:mm A";
var POMPEII_DATE_TIME_FORMAT_WITH_WEEK_DAY = "MM/DD/YYYY hh:mm A ddd";
var POMPEII_TIME_FORMAT = "hh:mm A"; // Use anywhere date formatting is needed
var MOMENT_12H_TIME_FORMAT = "hh:mm A";
var MOMENT_24H_TIME_FORMAT = "HH:mm";
var MOMENT_DATE_TIME_FORMAT = "MM/DD/YYYY hh:mm A";
var MOMENT_DATE_FORMAT = "MM/DD/YYYY";

var AUTO_LOGOUT_IN_MINS = 30;
var INACTIVITY_POPUP_TIME_IN_MINS = 2;

var SESSION_CHECK_AUTOLOGOUT_WARNING_TIME = (INACTIVITY_POPUP_TIME_IN_MINS * 60 * 1000);
var COOKIE_CHECK_TIMER_LIMIT = (AUTO_LOGOUT_IN_MINS * 60 * 1000) - SESSION_CHECK_AUTOLOGOUT_WARNING_TIME;
var COOKIE_CHECK_TIMER_LIMIT_IN_MINS = AUTO_LOGOUT_IN_MINS - INACTIVITY_POPUP_TIME_IN_MINS;

var EVENT_APPOINTMENT_SELECTED = "POMPEII_APPOINTMENT_SELECTED_EVENT";

var ENTER_KEYCODE = 13;
var TAB_KEYCODE = 9;
function PompeiiCookies() {
    var self = this;

    self.clearAuthCookies = function () {
        Cookies.remove(COOKIE_POMPEII_ACCESSTOKEN, { path: "/" });
        Cookies.remove(COOKIE_POMPEII_USER, { path: "/" });
        Cookies.remove(COOKIE_FOTO_ENABLED, { path: "/" });
        Cookies.remove(COOKIE_SCHEDULING_ENABLED, { path: "/" });
        Cookies.remove(COOKIE_IE11WARNINGMESSAGE, { path: "/" });
        Cookies.remove(COOKIE_IPL_ENABLED, { path: "/" });
        Cookies.remove(COOKIE_CAN_FAX, { path: "/" });
        Cookies.remove(COOKIE_FAX_TOP_MARGIN, { path: "/" });
        Cookies.remove(COOKIE_FLOWSHEETS_ENABLED, { path: "/" });
        Cookies.remove(COOKIE_NURSING_WORKFLOWS_ENABLED, { path: "/" });
        Cookies.remove(COOKIE_ORDERS_ENABLED, { path: "/" });
        Cookies.remove(COOKIE_INTERVENTIONS_ENABLED, { path: "/" });
        Cookies.remove(COOKIE_TIMEENTRY_ENABLED, { path: "/" });
        Cookies.remove(COOKIE_LASTACTIVITYTIME, { path: "/" });
        Cookies.remove(COOKIE_DICTIONARIES_ENABLEDREM, { path: "/" });
        Cookies.remove(COOKIE_DOCUMENTATION_ENABLESENDTOTHIRDPARTY, { path: "/" });
        Cookies.remove(COOKIE_DOCUMENTATION_ENABLESENDTOTHIRDPARTYMESSAGE, { path: "/" });
        Cookies.remove(COOKIE_SMART_TEXT_ENABLED, { path: "/" });
    }

    self.getCookieOptions = function () {

        var d = new Date();
        d.setFullYear(d.getFullYear() + 1);

        return {
            path: "/",
            expires: d,
        };
    }

    self.getCookieOptions_Day = function () {
        var date = new Date();
        date.setDate(date.getDate() + 1);

        return {
            path: "/",
            expires: date
        };
    }

    self.getCookieOptions_Session = function () {
        return {
            path: "/",
        };
    }

    self.getSystemCode = function () {
        return Cookies.get(COOKIE_POMPEII_SYSTEMCODE);
    }

    self.setSystemCode = function (code) {
        Cookies.set(COOKIE_POMPEII_SYSTEMCODE, code, self.getCookieOptions())
    }

    self.getAccessToken = function () {
        return Cookies.get(COOKIE_POMPEII_ACCESSTOKEN);
    }

    self.setAccessToken = function (token) {
        Cookies.set(COOKIE_POMPEII_ACCESSTOKEN, token, self.getCookieOptions_Session())
    }

    self.getUser = function () {
        return JSON.parse(Cookies.get(COOKIE_POMPEII_USER));
    }

    self.setUser = function (user) {
        Cookies.set(COOKIE_POMPEII_USER, JSON.stringify(user), self.getCookieOptions_Session());
    }

    self.getBuildVersion = function () {
        return Cookies.get(COOKIE_BUILDVERSION);
    }

    self.GetCheckTimer = function () {
        return moment(Cookies.get(COOKIE_CHECK_TIMER));
    };

    self.SetCheckTimer = function (time) {
        Cookies.set(COOKIE_CHECK_TIMER, time, self.getCookieOptions_Session());
    };

    self.getFotoEnabled = function () {
        if (Pompeii.IsNullOrUndefined(Cookies.get(COOKIE_FOTO_ENABLED))) {
            return null;
        }
        return JSON.parse(Cookies.get(COOKIE_FOTO_ENABLED));
    };

    self.setFotoEnabled = function (value) {
        Cookies.set(COOKIE_FOTO_ENABLED, value, self.getCookieOptions_Session());
    };

    self.getSchedulingEnabled = function () {
        if (Pompeii.IsNullOrUndefined(Cookies.get(COOKIE_SCHEDULING_ENABLED))) {
            return true;
        }
        return JSON.parse(Cookies.get(COOKIE_SCHEDULING_ENABLED));
    };

    self.setSchedulingEnabled = function (value) {
        Cookies.set(COOKIE_SCHEDULING_ENABLED, value, self.getCookieOptions_Session());
    };

    self.setSessionCookie = function (key, value) {
        Cookies.set(key, value, self.getCookieOptions_Session());
        trackSessionCookie(key);
    }

    self.getSessionCookie = function (key) {
        return Cookies.get(key);
    }

    self.clearSessionCookies = function () {
        //Delete all cookies that were stored using the setSessionCookie method.
        var cookieList = self.getSessionCookie(COOKIE_POMPEII_SESSIONCOOKIES);
        if (cookieList) {
            try {
                cookieList = JSON.parse(cookieList);
                cookieList.forEach( (cookieKey, index) => {
                    Cookies.remove(cookieKey, { path: "/" });
                })
            }
            catch (jsError) {
                try {
                    var objErrorContext = ErrorLogger.ReadError(jsError);
                    ErrorLogger.ReportEvent(objErrorContext);
                } catch (e) { }
            }
        }
        Cookies.remove(COOKIE_POMPEII_SESSIONCOOKIES, { path: "/" });
    }

    self.getNotificationFacility = function () {
        return Cookies.get(COOKIE_POMPEII_NOTIFICATIONFACILITY);
    };

    self.setNotificationFacility = function (value) {
        Cookies.set(COOKIE_POMPEII_NOTIFICATIONFACILITY, value, self.getCookieOptions_Session());
    };

    self.setHelpAuth = function () {
        //don't install the auth token unless on a public facing system
        var dom = self.getDomain();
        if (dom.indexOf(".com") != -1) { //don't install cookie for localhost, etc...
            Cookies.set("PPH_AUTH", "auth", { path: '/', domain: dom });
        }
    };

    self.removeHelpAuth = function () {
        var dom = self.getDomain();
        Cookies.remove(COOKIE_HELP_AUTH, { path: '/', domain: dom });
    };

    self.getDomain = function () {
        var host = document.location.hostname;
        //get the domain part of the host, add leading '.' for full domain visibility
        if (host.indexOf(".com") != -1)
            return "." + host.substring(host.lastIndexOf(".", host.lastIndexOf(".") - 1) + 1);
        return host; //not a "real" domain
    };

    self.getIE11Warning = function () {
        return Cookies.get(COOKIE_IE11WARNINGMESSAGE);
    }

    self.setIE11Warning= function () {
        Cookies.set(COOKIE_IE11WARNINGMESSAGE, true, self.getCookieOptions_Day());
    }

    self.getIplEnabled = function () {
        if (Pompeii.IsNullOrUndefined(Cookies.get(COOKIE_IPL_ENABLED))) {
            return false;
        }
        return JSON.parse(Cookies.get(COOKIE_IPL_ENABLED));
    };

    self.setIplEnabled = function (value) {
        Cookies.set(COOKIE_IPL_ENABLED, value, self.getCookieOptions_Session());
    };

    self.getEnabledRem = function () {
        if (Pompeii.IsNullOrUndefined(Cookies.get(COOKIE_DICTIONARIES_ENABLEDREM))) {
            return false;
        }
        return JSON.parse(Cookies.get(COOKIE_DICTIONARIES_ENABLEDREM));
    };

    self.setEnabledRem = function (value) {
        Cookies.set(COOKIE_DICTIONARIES_ENABLEDREM, value, self.getCookieOptions_Session());
    };

    self.setEnableSendToThirdParty = function (value) {
        Cookies.set(COOKIE_DOCUMENTATION_ENABLESENDTOTHIRDPARTY, value, self.getCookieOptions_Session());
    };
    self.getEnableSendToThirdParty = function () {
        if (Pompeii.IsNullOrUndefined(Cookies.get(COOKIE_DOCUMENTATION_ENABLESENDTOTHIRDPARTY))) {
            return false;
        }
        return JSON.parse(Cookies.get(COOKIE_DOCUMENTATION_ENABLESENDTOTHIRDPARTY));
    };

    self.setEnableSendToThirdPartyMessage = function (value) {
        Cookies.set(COOKIE_DOCUMENTATION_ENABLESENDTOTHIRDPARTYMESSAGE, value, self.getCookieOptions_Session());
    };
    self.getEnableSendToThirdPartyMessage = function () {
        if (Pompeii.IsNullOrUndefined(Cookies.get(COOKIE_DOCUMENTATION_ENABLESENDTOTHIRDPARTYMESSAGE))) {
            return false;
        }
        return JSON.parse(Cookies.get(COOKIE_DOCUMENTATION_ENABLESENDTOTHIRDPARTYMESSAGE));
    };

    self.getFlowsheetsEnabled = function () {
        if (Pompeii.IsNullOrUndefined(Cookies.get(COOKIE_FLOWSHEETS_ENABLED))) {
            return false;
        }
        return JSON.parse(Cookies.get(COOKIE_FLOWSHEETS_ENABLED));
    };

    self.setFlowsheetsEnabled = function (value) {
        Cookies.set(COOKIE_FLOWSHEETS_ENABLED, value, self.getCookieOptions_Session());
    };

    self.getNursingWorkflowsEnabled = function () {
        if (Pompeii.IsNullOrUndefined(Cookies.get(COOKIE_NURSING_WORKFLOWS_ENABLED))) {
            return false;
        }
        return JSON.parse(Cookies.get(COOKIE_NURSING_WORKFLOWS_ENABLED));
    };

    self.setNursingWorkflowsEnabled = function (value) {
        Cookies.set(COOKIE_NURSING_WORKFLOWS_ENABLED, value, self.getCookieOptions_Session());
    };

    self.getOrdersEnabled = function () {
        if (Pompeii.IsNullOrUndefined(Cookies.get(COOKIE_ORDERS_ENABLED))) {
            return false;
        }
        return JSON.parse(Cookies.get(COOKIE_ORDERS_ENABLED));
    };

    self.setOrdersEnabled = function (value) {
        Cookies.set(COOKIE_ORDERS_ENABLED, value, self.getCookieOptions_Session());
    };

    self.getOrdersWorkflowsEnabled = function () {
        if (Pompeii.IsNullOrUndefined(Cookies.get(COOKIE_ORDERS_WORKFLOWS_ENABLED))) {
            return false;
        }
        return JSON.parse(Cookies.get(COOKIE_ORDERS_WORKFLOWS_ENABLED));
    };

    self.setOrdersWorkflowsEnabled = function (value) {
        Cookies.set(COOKIE_ORDERS_WORKFLOWS_ENABLED, value, self.getCookieOptions_Session());
    };

    self.getInterventionsEnabled = function () {
        if (Pompeii.IsNullOrUndefined(Cookies.get(COOKIE_INTERVENTIONS_ENABLED))) {
            return false;
        }
        return JSON.parse(Cookies.get(COOKIE_INTERVENTIONS_ENABLED));
    };

    self.setInterventionsEnabled = function (value) {
        Cookies.set(COOKIE_INTERVENTIONS_ENABLED, value, self.getCookieOptions_Session());
    };

    self.getTimeEntryEnabled = function () {
        if (Pompeii.IsNullOrUndefined(Cookies.get(COOKIE_TIMEENTRY_ENABLED))) {
            return false;
        }
        return JSON.parse(Cookies.get(COOKIE_TIMEENTRY_ENABLED));
    };

    self.setTimeEntryEnabled = function (value) {
        Cookies.set(COOKIE_TIMEENTRY_ENABLED, value, self.getCookieOptions_Session());
    };

    self.getLastActivityTime = function () {
        if (Pompeii.IsNullOrUndefined(Cookies.get(COOKIE_LASTACTIVITYTIME))) {
            return null;
        }
        return moment(Cookies.get(COOKIE_LASTACTIVITYTIME));
    };

    self.setLastActivityTime = function (value) {
        Cookies.set(COOKIE_LASTACTIVITYTIME, value, self.getCookieOptions_Session());
    };

    self.getAccessDataBlockingEnabled = function () {
        if (Pompeii.IsNullOrUndefined(Cookies.get(COOKIE_ACCESS_DATA_BLOCKING_ENABLED))) {
            return false;
        }
        return JSON.parse(Cookies.get(COOKIE_ACCESS_DATA_BLOCKING_ENABLED));
    };

    self.setAccessDataBlockingEnabled = function (value) {
        Cookies.set(COOKIE_ACCESS_DATA_BLOCKING_ENABLED, value, self.getCookieOptions_Session());
    };

    self.getSmartTextEnabled = function () {
        if (Pompeii.IsNullOrUndefined(Cookies.get(COOKIE_SMART_TEXT_ENABLED))) {
            return false;
        }
        return JSON.parse(Cookies.get(COOKIE_SMART_TEXT_ENABLED));
    };

    self.setSmartTextEnabled = function (value) {
        Cookies.set(COOKIE_SMART_TEXT_ENABLED, value, self.getCookieOptions_Session());
    };

    function trackSessionCookie(key) {
        //This will keep a list of all session cookies in a special cookie that can be used later by the clearSessionCookies method.
        var cookieList = self.getSessionCookie(COOKIE_POMPEII_SESSIONCOOKIES);
        if (!cookieList)
            cookieList = [];
        else
            cookieList = JSON.parse(cookieList);

        if (cookieList.indexOf(key) == -1) {
            cookieList.push(key);
            Cookies.set(COOKIE_POMPEII_SESSIONCOOKIES, JSON.stringify(cookieList), self.getCookieOptions_Session());
        }
    }
}
export {
    PompeiiCookies
}
