
import '../Content/styles/wellsky-login.scss';
import { MDCDrawer } from "@material/drawer";
import { MDCTopAppBar } from "@material/top-app-bar";
import { MDCTextField } from '@material/textfield';
import { MDCNotchedOutline } from '@material/notched-outline';
import { MDCList } from '@material/list';
import { MDCRipple } from '@material/ripple';
import { MDCTextFieldHelperText } from '@material/textfield/helper-text';
import Vesuvius from '../Scripts/pompeii/v2/pompeii.js';
import moment from 'moment';
import defaultExport from '../Scripts/pompeii/v2/browser-detect';
import * as PK from './cookie';

var utils = {
    showElement: function (elm) {
        if (elm !== null && elm !== undefined) {
            if (elm.classList.contains('d-none')) {
                elm.classList.remove('d-none');
            }
        }
    },
    hideElement: function (elm) {
        if (elm !== null && elm !== undefined) {
            if (!elm.classList.contains('d-none')) {
                elm.classList.add('d-none');
            }
        }
    }
}

function MessageHandler(containerId, messageId) {
    this.containerId = containerId;
    this.messageId = messageId;
    this.message = '';
    this.set = function (message) {
        this.message = message;
        document.querySelector('#' + this.messageId).innerHTML = message;

        if (!message) {
            utils.hideElement(document.querySelector('#' + this.containerId));
        } else {
            utils.showElement(document.querySelector('#' + this.containerId));
        }
    }
}

var login = function (baseUrl, clientAddress) {
    // Variables

    let systemHandler = new MessageHandler('systemMessageContainer_Id', 'systemMessage_Id');
    let systemMessageMaintHandler = new MessageHandler('simpleMaintenanceMessage_Id', 'systemMessageMaint_Id');
    let errorHandler = new MessageHandler('loginErrorContainer_Id', 'loginError_Id');
    let passwordErrorHandler = new MessageHandler('passwordErrorContainer_Id', 'passwordError_Id');
    let passwordUpdateHandler = new MessageHandler('passwordUpdatedMessageContainer_Id', 'passwordUpdatedMessage_Id');

    let loginForm = document.querySelector('#login-form');
    let recommendForm = document.querySelector('#pw-recommend');
    let changePwForm = document.querySelector('#changePasswordForm_Id');
    let browserSupport = document.querySelector('#browser-supported-check');
    let passwordPastDue = document.querySelector('#passwordPassedDue');
    let pcookies = new PK.PompeiiCookies();

    // Drawer - the main component in the Login page UI for the page structure
    // Documentation: https://material.io/develop/web/components/drawers/
    const drawer = MDCDrawer.attachTo(document.querySelector('.mdc-drawer'));

    // Top App Bar - The top navigation bar used in the login page
    // In the smaller resolution it helps to close or open the aside drawer
    // Documentation: https://material.io/develop/web/components/top-app-bar/
    const topAppBar = MDCTopAppBar.attachTo(document.getElementById('app-bar'));
    topAppBar.setScrollTarget(document.getElementById('main-content'));
    topAppBar.listen('MDCTopAppBar:nav', () => {
        drawer.open = !drawer.open;
    });

    // Checkbox - Remember me checkbox
    // Documentation: https://material.io/develop/web/components/input-controls/checkboxes/
    //const checkboxRememberMe = new MDCCheckbox(document.querySelector('.mdc-checkbox'));
    //const formField = new MDCFormField(document.querySelector('.mdc-form-field'));
    //formField.input = checkboxRememberMe;

    // Text Fields - The text field component is being used for input fields(username and password)
    // Documentation: https://material.io/components/web/catalog/input-controls/text-field/

    // Text field username
    const textFieldUsername = new MDCTextField(document.querySelector('#mdc-text-field--ws-username'));
    // Text field Password
    const textFieldPassword = new MDCTextField(document.querySelector('#mdc-text-field--ws-password'));
    // Text field system code
    const textFieldSystemCode = new MDCTextField(document.querySelector('#mdc-text-field--ws-systemcode'));

    // Text field username
    const textFieldCurrentPassword = new MDCTextField(document.querySelector('#mdc-text-field--ws-current-password'));
    // Text field Password
    const textFieldNewPassword = new MDCTextField(document.querySelector('#mdc-text-field--ws-new-password'));
    // Text field system code
    const textFieldConfirmPassword = new MDCTextField(document.querySelector('#mdc-text-field--ws-confirm-new-password'));

    textFieldCurrentPassword.useNativeValidation = false;
    textFieldNewPassword.useNativeValidation = false;
    textFieldConfirmPassword.useNativeValidation = false;

    // Notched Outline - To have a border around all sides of the Text Fields
    // Documentation: https://material.io/develop/web/components/input-controls/notched-outline/
    const notchedOutline = new MDCNotchedOutline(document.querySelector('.mdc-notched-outline'));

    // Helper Text - Helper text gives context about a field’s input.
    // It is used for showing required field validation.
    // Documentation: https://material.io/develop/web/components/input-controls/text-field/helper-text/
    const helperText = new MDCTextFieldHelperText(document.querySelector('.mdc-text-field-helper-text'));

    // Lists - It is used to show the items in aside menu of the drawer(mobile devices)
    // Documentation: https://material.io/develop/web/components/lists/ 
    const list = new MDCList(document.querySelector('.mdc-list'));
    const listItemRipples = list.listElements.map((listItemEl) => new MDCRipple(listItemEl));

    // Button Ripple - The button will work without JavaScript
    // Here is is used to have a ripple effect by instantiating 
    // Documentation: https://material.io/develop/web/components/buttons/
    const buttonRippleLogin = new MDCRipple(document.querySelector('.mdc-button'));

    let clientData = {
        clientAddress: clientAddress,
        baseUrl: baseUrl
    }

    // Methods

    function clear() {
        let systemCode = pcookies.getSystemCode();

        textFieldUsername.value = '';
        textFieldPassword.value = '';
        textFieldCurrentPassword.value = '';
        textFieldNewPassword.value = '';
        textFieldConfirmPassword.value = '';

        pcookies.clearAuthCookies();
        pcookies.clearSessionCookies();

        // MDC will not show notched label unless we wait
        window.setTimeout(function () {
            textFieldSystemCode.value = !systemCode ? '' : systemCode;
            textFieldUsername.focus();
        }, 50);
    }

    function setAuthorization(authResult) {
        pcookies.setAccessToken(authResult.UserToken);
        pcookies.setSystemCode(authResult.SystemCode);
        pcookies.setUser(authResult.User);
        pcookies.setHelpAuth();
        pcookies.SetCheckTimer(moment().add(Vesuvius.Constants.COOKIE_CHECK_TIMER_LIMIT_IN_MINS, 'minute').format());
        pcookies.setSchedulingEnabled(authResult.SchedulingEnabled);
        pcookies.setIplEnabled(authResult.IplEnabled);
        pcookies.setFlowsheetsEnabled(authResult.FlowsheetsEnabled);
        pcookies.setNursingWorkflowsEnabled(authResult.NursingWorkflowsEnabled);
        pcookies.setOrdersEnabled(authResult.OrdersEnabled);
        pcookies.setOrdersWorkflowsEnabled(authResult.OrdersWorkflowsEnabled);
        pcookies.setInterventionsEnabled(authResult.InterventionsEnabled);
        pcookies.setTimeEntryEnabled(authResult.TimeEntryEnabled);
        pcookies.setAccessDataBlockingEnabled(authResult.AccessDataBlockingEnabled);
        pcookies.setEnabledRem(authResult.EnabledREM);
        pcookies.setEnableSendToThirdParty(authResult.EnableSendToThirdParty);
        pcookies.setEnableSendToThirdPartyMessage(authResult.EnableSendToThirdPartyMessage);
        pcookies.setSmartTextEnabled(authResult.SmartTextEnabled);
        pcookies.setUserFacs(btoa(JSON.stringify(authResult.Facilities)));
        pcookies.setUserRights(btoa(JSON.stringify(authResult.Rights)));
        pcookies.setUserSettings(btoa(JSON.stringify(authResult.Settings)));
        pcookies.setUserConfigSettings(btoa(JSON.stringify(authResult.ConfigGlobalSettings)));

        sessionStorage.setItem("userFacilities", JSON.stringify(authResult.Facilities));
        sessionStorage.setItem("userRights", JSON.stringify(authResult.Rights));
        sessionStorage.setItem("userSettings", JSON.stringify(authResult.Settings));
        sessionStorage.setItem("configSettings", JSON.stringify(authResult.ConfigGlobalSettings));
    }

    function getLocation() {
        var paPatientList = Vesuvius.Helpers.GetQueryStringParameter('pa');

        if (paPatientList === 'pl') {
            return '/Pa';
        }

        return '/Home';
    }

    function getPasswordDaysLeft(authResult) {
        let pwdExpires = moment(authResult.PasswordExpirationDate).startOf('day');
        let daysLeft = pwdExpires.diff(moment().startOf('day'), 'days');

        if (daysLeft < 0) {
            daysLeft = 0;
        }

        return daysLeft;
    }

    function authPassed(authResult) {
        setAuthorization(authResult);
        let pwDaysLeft = getPasswordDaysLeft(authResult);
        let location = getLocation();

        if (authResult.PasswordNeverExpires) {
            startAuthenticating();

            if (authResult.User.IsScheduler) {
                Vesuvius.Helpers.AEGo({ user: pcookies.getUser().AEUser, token: pcookies.getAccessToken(), system: pcookies.getUser().System, replacetab: true, grid: true });
            } else {
                window.location.pathname = location;
            }
        } else if ((pwDaysLeft <= Vesuvius.Constants.LOGIN_PASS_DAYS_LEFT_CUTOFF)) {
            passwordRecommendChangeState(pwDaysLeft);
        } else {
            startAuthenticating();
            if (authResult.User.IsScheduler) {
                Vesuvius.Helpers.AEGo({ user: pcookies.getUser().AEUser, token: pcookies.getAccessToken(), system: pcookies.getUser().System, replacetab: true, grid: true });
            } else {
                window.location.pathname = location;
            }
        }
    }

    function samlAuthPassed(authResult) {
        startAuthenticating();
        setAuthorization(authResult);
        let canAccessPAPlus = pcookies.getUser().Practitioner.CanReviewPaPlusDocuments;

        if (!canAccessPAPlus) {
            window.location.pathname = '/Home';
        } else {
            if (confirm('Would you like to view pre-admission documentation?')) {
                window.location.pathname = '/Pa';
            } else {
                window.location.pathname = '/Home';
            }
        }
    }

    function missingSystemCode() {
        errorHandler.set("Please provide a system code.");
        focusNextEmptyInput();
    }

    function focusNextEmptyInput() {
        if (!textFieldUsername.value) {
            textFieldUsername.focus();
        } else if (!textFieldPassword.value) {
            textFieldPassword.focus();
        } else {
            textFieldSystemCode.focus();
        }
    }

    function authFailed(authResult) {
        if (authResult.Result === Vesuvius.Enums.AuthenticateResult.Fail) {
            errorHandler.set("Login Failed. Please verify that you typed your credentials correctly and try again.");
        } else if (authResult.Result === Vesuvius.Enums.AuthenticateResult.ExpiredPassword) {
            passwordChangeState(0);
        } else if (authResult.Result === Vesuvius.Enums.AuthenticateResult.AccountLocked || authResult.Result === Vesuvius.Enums.AuthenticateResult.Error) {
            errorHandler.set(authResult.Message);
        } else if (authResult.CULExceeded) {
            errorHandler.set("Total logged in users is currently at the maximum allowed for your subscription, please try back later.");
        } else {
            errorHandler.set("Login Failed. Please contact product support.");
        }
    }

    function passwordChangeSuccess() {
        clear();
        loginFormChangeState();
        passwordUpdateHandler.set('Your password has been updated');
    }

    function passwordChangeFailed(authResponse) {
        passwordErrorHandler.set(authResponse.Message);
    }

    // UI STATES

    function noBrowserSupportChangeState() {
        utils.hideElement(loginForm);
        utils.hideElement(recommendForm);
        utils.hideElement(changePwForm);
        utils.hideElement(passwordPastDue);
        utils.showElement(browserSupport);
    }

    function loginFormChangeState() {
        utils.hideElement(browserSupport);
        utils.hideElement(recommendForm);
        utils.hideElement(changePwForm);
        utils.hideElement(passwordPastDue);
        utils.showElement(loginForm);
    }

    function passwordRecommendChangeState(daysLeft) {
        document.querySelector('#pw-days-left').innerHTML = daysLeft;

        utils.hideElement(loginForm);
        utils.hideElement(browserSupport);
        utils.hideElement(changePwForm);
        utils.hideElement(passwordPastDue);
        utils.showElement(recommendForm);
    }

    function passwordChangeState(daysLeft) {
        if (daysLeft <= 0) {
            utils.showElement(passwordPastDue);
        } else {
            utils.hideElement(passwordPastDue);
        }

        utils.hideElement(browserSupport);
        utils.hideElement(recommendForm);
        utils.hideElement(loginForm);
        utils.showElement(changePwForm);
    }

    function startAuthenticating() {
        let inputAreas = document.querySelectorAll('.ws-input-area');
        let waiter = document.querySelector('#sign-in-loader');
        let btns = document.querySelectorAll('.mdc-layout-grid button');

        inputAreas.forEach(elm => {
            utils.hideElement(elm);
        });

        btns.forEach(elm => {
            elm.disabled = true;
        });

        utils.showElement(waiter);
    }

    function stopAuthenticating() {
        let inputAreas = document.querySelectorAll('.ws-input-area');
        let waiter = document.querySelector('#sign-in-loader');
        let btns = document.querySelectorAll('.mdc-layout-grid button');

        inputAreas.forEach(elm => {
            utils.showElement(elm);
        });

        btns.forEach(elm => {
            elm.disabled = false;
        });

        utils.hideElement(waiter);
    }

    // VALIDATION

    function validatePasswordChangeConfirm() {
        if (textFieldConfirmPassword.value && textFieldConfirmPassword.value !== textFieldNewPassword.value) {
            textFieldConfirmPassword.valid = false;
            textFieldConfirmPassword.helperTextContent = 'Password mismatch, please try again.';
        } else {
            textFieldConfirmPassword.valid = true;
        }
    }

    function validatePasswordChangeNew() {
        var result = Vesuvius.Helpers.CheckPasswordComplexity(textFieldNewPassword.value);
        if (!result.Passed) {
            textFieldNewPassword.valid = false;
            textFieldNewPassword.helperTextContent = result.Message;
        } else {
            textFieldNewPassword.valid = true;
        }

        // Double check our new password
        validatePasswordChangeConfirm();
    }

    function validatePasswordChangeCurrent() {
        if (textFieldCurrentPassword.value && textFieldCurrentPassword.value !== textFieldPassword.value) {
            textFieldCurrentPassword.valid = false;
            textFieldCurrentPassword.helperTextContent = 'Incorrect current password, please try again.';
        } else {
            textFieldCurrentPassword.valid = true;
        }
    }

    function setSystemMessage(message) {
        let mainGrid = document.querySelector('#main-grid-container');

        if (message.length > 0) {
            message.forEach(m => {
                if (m.Type === 'SYSTEMMESSAGE') {
                    systemHandler.set(m.Message);
                }
                else if (m.Type === 'SYSTEMMESSAGEMAINT') {
                    systemMessageMaintHandler.set(m.Message);
                }
            });

            if (mainGrid !== null && mainGrid !== undefined) {
                if (!mainGrid.classList.contains('higher-up')) {
                    mainGrid.classList.add('higher-up');
                }
            }
        } else {
            if (mainGrid !== null && mainGrid !== undefined) {
                if (mainGrid.classList.contains('higher-up')) {
                    mainGrid.classList.remove('higher-up');
                }
            }
        }
    }

    function samlCheck() {
        const url = new URL(window.location.href);
        const result = url.searchParams.get("responseResult");
        const loginID = url.searchParams.get("loginID");
        const otherID = url.searchParams.get("otherID");
        const firstName = url.searchParams.get("firstName");
        const systemCode = url.searchParams.get("systemCode");

        if (!Vesuvius.Helpers.IsStringNullOrEmpty(result)) {
            const uri = window.location.toString();
            window.history.replaceState({}, document.title, uri.substring(0, uri.indexOf('?')));
            completeSamlLogin(result, loginID, otherID, firstName, systemCode);
        }
    }

    // API METHODS

    function submitPasswordChange(event) {
        startAuthenticating();
        const url = clientData.baseUrl + 'api/Authentication/ChangeActivePassword';
        const data = { op: textFieldCurrentPassword.value, np: textFieldNewPassword.value, npc: textFieldConfirmPassword.value, u: textFieldUsername.value, SystemCode: textFieldSystemCode.value };
        var xhttp = new XMLHttpRequest();

        xhttp.onreadystatechange = function (oEvent) {
            if (xhttp.readyState === 4) {
                stopAuthenticating();

                if (xhttp.status === 200) {
                    let response = JSON.parse(xhttp.responseText);

                    if (response && response.Success === true) {
                        passwordChangeSuccess();
                    } else if (response) {
                        passwordChangeFailed(response);
                    } else {
                        Vesuvius.Helpers.ReportAjaxError(xhttp, xhttp.statusText, 'No Response', 'Password Change', 'simplelogin.js');
                    }
                } else {
                    Vesuvius.Helpers.ReportAjaxError(xhttp, xhttp.statusText, 'Failed to call endpoint', 'Password Change', 'simplelogin.js');
                }
            }
        };

        xhttp.open("POST", url, true);
        xhttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
        xhttp.send(JSON.stringify(data));
        event.preventDefault();
    }

    function getSystemMessage() {
        const url = clientData.baseUrl + 'api/Authentication/GetSysAndMaintMessage';
        var xhttp = new XMLHttpRequest();

        xhttp.onreadystatechange = function (oEvent) {
            if (xhttp.readyState === 4) {
                if (xhttp.status === 200) {
                    let response = JSON.parse(xhttp.responseText);
                    setSystemMessage(response);
                }
            }
        };

        xhttp.open("GET", url, true);
        xhttp.send();
    }

    function samlLogin(authResult) {
        startAuthenticating();
        pcookies.setSystemCode(authResult.SystemCode);

        const url = clientData.baseUrl + 'api/Authentication/SAMLLogin';
        const data = { SourceAddress: clientData.clientAddress, SamlAuthUrl: authResult.SamlRedirectLink, SystemCode: authResult.SystemCode }
        var xhttp = new XMLHttpRequest();

        xhttp.onreadystatechange = function (oEvent) {
            if (xhttp.readyState === 4) {
                stopAuthenticating();

                if (xhttp.status === 200) {
                    let response = JSON.parse(xhttp.responseText);
                    window.location.href = response;
                } else {
                    errorHandler.set("There was a problem navigating to the provided environment's single sign-on page.");
                }
            }
        };

        xhttp.open("POST", url, true);
        xhttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
        xhttp.send(JSON.stringify(data));
    }

    function completeSamlLogin(result, loginID, otherID, firstName, systemCode) {
        startAuthenticating();

        const url = clientData.baseUrl + 'api/Authentication/CompleteSAMLLogin';
        const data = { AuthAppType: 1, SourceAddress: clientData.clientAddress, SystemCode: systemCode, LoginID: loginID, OtherID: otherID, FirstName: firstName };
        var xhttp = new XMLHttpRequest();

        xhttp.onreadystatechange = function (oEvent) {
            if (xhttp.readyState === 4) {
                stopAuthenticating();

                if (xhttp.status === 200) {
                    let response = JSON.parse(xhttp.responseText);

                    if (response) {
                        if (response.Success === true) {
                            samlAuthPassed(response);
                        } else {
                            errorHandler.set(response.Message);
                        }
                    } else {
                        errorHandler.set('Single sign-on request could not be verified.');
                    }
                } else {
                    errorHandler.set('Single sign-on request could not be verified.');
                }
            }
        };

        xhttp.open("POST", url, true);
        xhttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
        xhttp.send(JSON.stringify(data));
    }

    function submitForm(event) {
        // submitForm() is a function and can be utilized for page AJAX call
        console.time("Authentication");

        if (!textFieldSystemCode.value) {
            missingSystemCode();
            event.preventDefault();
            return false;
        }

        sessionStorage.removeItem("WSR_AUTH_START_TIME");
        sessionStorage.setItem("WSR_AUTH_START_TIME", (new Date()).getTime());
        startAuthenticating();

        const url = clientData.baseUrl + 'api/Authentication/Login';
        const data = { AuthAppType: 1, SystemCode: textFieldSystemCode.value, u: textFieldUsername.value, p: textFieldPassword.value, SourceAddress: clientData.clientAddress };
        var xhttp = new XMLHttpRequest();

        xhttp.onreadystatechange = function (oEvent) {
            if (xhttp.readyState === 4) {
                stopAuthenticating();

                if (xhttp.status === 200) {
                    console.timeEnd("Authentication");

                    // Check success
                    if (sessionStorage.getItem("WSR_AUTH_START_TIME")) {
                        var authStartTime = sessionStorage.getItem("WSR_AUTH_START_TIME");
                        var authEndTime = (new Date).getTime();
                        var totalAuthTime = ((authStartTime < authEndTime) ? authEndTime - authStartTime : 0);

                        sessionStorage.removeItem("WSR_AUTH_START_TIME");
                        sessionStorage.setItem("WSR-AUTH-TIME", totalAuthTime);
                    }

                    console.log("Authentication Time = " + totalAuthTime);
                    sessionStorage.removeItem("WSR_LOGIN_START_TIME");
                    sessionStorage.removeItem("WSR_LOGIN_CALL");
                    sessionStorage.setItem("WSR_LOGIN_START_TIME", (new Date()).getTime());

                    let response = JSON.parse(xhttp.responseText);

                    if (response && response.Success === true) {
                        sessionStorage.setItem("WSR_LOGIN_CALL", "Authentication");
                        authPassed(response);
                    } else if (response && response.Result === Vesuvius.Enums.AuthenticateResult.UseSamlAuth) {
                        sessionStorage.setItem("WSR_LOGIN_CALL", "Authentication UseSamlAuth");
                        samlLogin(response);
                    } else if (response) {
                        authFailed(response);
                    } else {
                        Vesuvius.Helpers.ReportAjaxError(xhttp, xhttp.statusText, 'No Response', 'Login Authentication', 'simplelogin.js');
                    }
                } else {
                    Vesuvius.Helpers.ReportAjaxError(xhttp, xhttp.statusText, 'Failed to call endpoint', 'Login Authentication', 'simplelogin.js');
                }
            }
        };

        xhttp.open("POST", url, true);
        xhttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
        xhttp.send(JSON.stringify(data));
        event.preventDefault();
    }

    // Inits & Event Listeners

    document.querySelector('#mdc-text-field--ws-current-password input').addEventListener('blur', (event) => {
        validatePasswordChangeCurrent();
    });

    document.querySelector('#mdc-text-field--ws-new-password input').addEventListener('blur', (event) => {
        validatePasswordChangeNew();
    });

    document.querySelector('#mdc-text-field--ws-confirm-new-password input').addEventListener('blur', (event) => {
        validatePasswordChangeConfirm();
    });

    document.querySelector('#login-form').addEventListener('submit', submitForm);
    document.querySelector('#changePasswordForm_Id').addEventListener('submit', submitPasswordChange);

    document.querySelector("#change_password_btn").addEventListener("click", function () {
        passwordChangeState(1)
    });

    document.querySelector("#skip_btn").addEventListener("click", function () {
        startAuthenticating();
        window.location.pathname = '/Home';
    });

    // Initialize
    stopAuthenticating();
    clear();
    loginFormChangeState();
    getSystemMessage();
    samlCheck();
};

function isBrowserSupported() {
    defaultExport.init();
    return defaultExport.supportedForWeb();
}

function initialize(baseUrl, clientAddress) {
    login(baseUrl, clientAddress);
}

function notSupported() {
    let waiter = document.getElementById('sign-in-loader');
    let browserSupport = document.getElementById('browser-supported-check');
    utils.hideElement(waiter);
    utils.showElement(browserSupport);
}

// Here is the module export () - which exports the internal function of the JS file
// We can use it as login.confirmation() outside the scope of this file
// Why login.<function>? The file will export as login module. The configuration is mentioned in ./webpack.config.js
// Refer: login.cfm for the use

export {
    isBrowserSupported,
    initialize,
    notSupported
};