/// POMPEII CONSTANTS
export default new (function() {
    this.AE_BASE_DATE = "1899-12-30";

    this.COOKIE_POMPEII_USER = "POMPEII_USER";
    this.COOKIE_POMPEII_FULLNAME = "POMPEII_USER_FULLNAME";
    this.COOKIE_POMPEII_DATABASE = "POMPEII_DATABASE";
    this.COOKIE_POMPEII_RESOURCE = "POMPEII_RESOURCE";
    this.COOKIE_POMPEII_RESOURCE_NAME = "POMPEII_RESOURCE_NAME";
    this.COOKIE_POMPEII_ENVIRONMENT = "POMPEII_SELECTED_ENVIRONMENT";
    this.COOKIE_POMPEII_SYSTEMCODE = "POMPEII_SYSTEMCODE";
    this.COOKIE_POMPEII_ACCESSTOKEN = "POMPEII_ACCESSTOKEN";
    this.COOKIE_POMPEII_SESSIONCOOKIES = "POMPEII_SESSIONCOOKIES";
    this.COOKIE_POMPEII_NOTIFICATIONFACILITY = "POMPEII_NOTIFICATION_FACILITY";
    this.COOKIE_BUILDVERSION = "BuildVersion";
    this.COOKIE_CHECK_TIMER = "POMPEII_CHECK_TIMER";
    this.COOKIE_FOTO_ENABLED = "POMPEII_FOTO_ENABLED";
    this.COOKIE_SCHEDULING_ENABLED = "POMPEII_SCHEDULING_ENABLED";
    this.COOKIE_HELP_AUTH = "PPH_AUTH";
    this.COOKIE_IE11WARNINGMESSAGE = "IE11WARNINGMESSAGE";

    this.LOGIN_PASS_DAYS_LEFT_CUTOFF = 10;

    this.POMPEII_DATE_FORMAT = "MM/DD/YYYY";
    this.POMPEII_DATE_TIME_FORMAT = "MM/DD/YYYY hh:mm A";
    this.POMPEII_DATE_TIME_FORMAT_ORDERS_COMMENTS = "MM/DD/YYYY hh:mm:ss A";
    this.POMPEII_DATE_TIME_FORMAT_WITH_WEEK_DAY = "MM/DD/YYYY hh:mm A ddd";
    this.POMPEII_MIN_DATE = new Date(-8640000000000000);
    this.POMPEII_TIME_FORMAT = "hh:mm A";

    this.AUTO_LOGOUT_IN_MINS = 30;
    this.INACTIVITY_POPUP_TIME_IN_MINS = 2;

    this.EVENT_APPOINTMENT_SELECTED = "POMPEII_APPOINTMENT_SELECTED_EVENT";

    this.ENTER_KEYCODE = 13;
    this.TAB_KEYCODE = 9;

    this.SESSION_CHECK_AUTOLOGOUT_WARNING_TIME = this.INACTIVITY_POPUP_TIME_IN_MINS * 60 * 1000;
    this.COOKIE_CHECK_TIMER_LIMIT = (this.AUTO_LOGOUT_IN_MINS * 60 * 1000) - this.SESSION_CHECK_AUTOLOGOUT_WARNING_TIME;
    this.COOKIE_CHECK_TIMER_LIMIT_IN_MINS = this.AUTO_LOGOUT_IN_MINS - this.INACTIVITY_POPUP_TIME_IN_MINS;
})();