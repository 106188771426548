/// POMPEII HELPERS
import moment from 'moment';
import Constants from './constants';

export default new (function () {
    this.AEGo = function (params) {
        var url = ae_baseurl +
            "Login.aspx?aeml_user=" +
            (params && params.user ? params.user : puser.CurrentUser.AEUser) +
            "&aeml_token=" +
            (params && params.token ? params.token : pcookies.getAccessToken()) +
            "&aeml_system=" +
            (params && params.system ? params.system : puser.CurrentUser.System);

        if (params) {
            if (params.editAppt)
                url += "&editappt=" + params.editAppt;
            if (params.mlacct)
                url += "&mlacct=" + params.mlacct;
            if (params.demopage)
                url += "&demopage=" + params.demopage;
            if (params.grid)
                url += "&gogrid=true";
            if (params.querystring && params.querystring.length > 0) {
                if (params.querystring.slice(0, 1) != '&')
                    params.querystring = '&' + params.querystring;
                url += params.querystring;
            }
        }

        if (params && params.replacetab) {
            window.open(url, "_self");
        }
        else {
            window.open(url, "grid_by_ssoft");
        }
    };

    this.BackgroundTaskTimeout;

    this.BackgroundTaskHandler = function (items, numToProcess, processHandler, completeHandler, iterationTimeout) {
        var self = this;

        var pos = 0;
        if (iterationTimeout === undefined || iterationTimeout == null) {
            iterationTimeout = 50;
        }

        function iteration() {
            var taskLength = Math.min(pos + numToProcess, items.length);

            for (var i = pos; i < taskLength; i++) {
                processHandler(items[i]);
            }

            pos += numToProcess;
            if (pos < items.length) {
                self.BackgroundTaskTimeout = setTimeout(iteration, iterationTimeout);
            }
            else {
                if (completeHandler !== undefined && completeHandler != null) {
                    completeHandler(items);
                }
            }
        }

        iteration();
    };

    this.CalculateAgeFromDoB = function(dateofbirth) {
        var dob = moment(dateofbirth);
        var years = moment().diff(dob, 'years');
        var months = moment().diff(dob, 'months');
        var days = moment().diff(dob, 'days');

        // Check for zero date first
        if (dateofbirth.toString() == "1899-12-30T00:00:00" || dateofbirth.toString() == "0001-01-01T00:00:00") {
            return "";
        }
        else if (months < 1) {
            return days + "d";
        }
        else if (years < 2) {
            return months + "m";
        }
        else {
            return years + "y";
        }
    };

    this.CheckAjaxErrorForLogout = function (e) {
        //Check HTTP Status or Legacy code support
        if (e.status == 401 || (e.responseText !== undefined && e.responseText.indexOf("Invalid token or cookie") > 0)) {
            console.log(e.responseText);
            this.ReportAjaxError(e, e.statusText, e.responseText, 'CheckAjaxErrorForLogout', 'pompeii.core', null);

            if (!Pompeii.IsNullOrUndefined(documentViewModel)) {
                // On documentation have them re-authorize
                Pompeii.Vue.Document.CXShowLoginPopup();
            }
            else {
                // Move back to login page
                this.ClearCookiesAndReload();
            }

            return true;
        }
    };

    this.ClearCookiesAndReload = function () {
        pcookies.clearAuthCookies();
        pcookies.clearSessionCookies();
        pcookies.removeHelpAuth();
        sessionStorage.clear();

        // Don't stop reload because of unsaved documentation
        if (Pompeii.InDocumentation()) {
            Pompeii.Controllers.Document.NeedsQuickSave = false;
        }

        if (current_controller === "PlanOfCare" && (current_action === "Login" || current_action === "Index")) {
            document.location = site_baseurl + 'PlanOfCare/Logout';
        }
        else {
            document.location = site_baseurl + 'Account/Index';
        }
    };

    this.IsStringNullOrEmpty = function(value) {
        return (typeof value == "undefined") || value === null || value.toString().trim() === "";
    };

    this.IsNullOrUndefined = function(value) {
        return (typeof value == "undefined") || value === null;
    }

    this.IsNullDate = function(value) {
        if (value == null || value == '0001-01-01T00:00:00' || value == '1899-12-30T00:00:00') {
            return true;
        }

        return false;
    };

    this.FormatDateIfNotNull = function (value, formatter) {
        if (this.IsNullDate(value)) {
            return '';
        }

        if (!formatter) {
            formatter = Constants.POMPEII_DATE_FORMAT;
        }

        return moment(value).format(formatter);
    };

    this.IsFunction = function (value) {
        var getType = {};
        return value && getType.toString.call(value) === '[object Function]';
    };

    this.IsInMobileView = function () {
        if ($(window).width() < 800) {
            return true;
        }
        else {
            return false;
        }
    };

    this.Camelize = function (str) {
        return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
            if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
            return index == 0 ? match.toUpperCase() : match.toLowerCase();
        });
    };

    this.GetDateFromTime = function (time) {
        /* This function is meant to accept a time string such as "8:00PM" or "20:00",
             then return a ISO8601 json friendly date string with todays date and the time that was passed.
            If you pass in any kind of date object or ISO date string, it will just return it as a string.
            Ideally, the final else {} is the only code that should run here,
             but the function was found to be receiving other values so now it is fail safe.
        */

        if (!moment.isMoment(time) && typeof time.getMonth === 'function') {
            //The var is a JS date object, make a moment out of it.
            time = moment(time);
        }

        if (moment.isMoment(time)) {
            //The var is a Moment date/time object, return it as a string.
            return time.format();
        }
        else if (moment(time, moment.ISO_8601, true).isValid()) {
            //The var is already a string with an ISO8601 date, I don't need to change it at all.
            return time;
        }
        else {
            //The var should be a string with just the time of day. "8:30 PM", now turn it into a json date string
            return moment(moment().format("MM-DD-YYYY") + " " + time, "MM-DD-YYYY " + Pompeii.Constants.POMPEII_TIME_FORMAT).format();
        }
    };

    this.CheckPasswordComplexity = function (pass) {
        /*
         * This regex will require a 4 flavor match, UPPER, LOWER, NUMBER, and SPECIAL characters. It is too strict for the current
         * Mediware policy which only requires any 3 of the 4.
         * var REGEX_PASSWORD_COMPLEX = /(?=(.*[0-9]))(?=.*[\!@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,.\/?])(?=.*[a-z])(?=(.*[A-Z]))(?=(.*)).{10,}/g;
         * We will break it down into seperate expressions
        */
        var _regexPasswordLength = /.{10,}/g;
        var _regexPasswordUpper = /[A-Z]/g;
        var _regexPasswordLower = /[a-z]/g;
        var _regexPasswordNumber = /\d/g;
        var _regexPasswordSpecial = /[\W_]/g;

        var long = _regexPasswordLength.test(pass);

        var score = 0;
        if (_regexPasswordUpper.test(pass)) score++;
        if (_regexPasswordLower.test(pass)) score++;
        if (_regexPasswordNumber.test(pass)) score++;
        if (_regexPasswordSpecial.test(pass)) score++;

        var complex = false;
        if (score >= 3)
            complex = true;

        var grade;
        switch (score) {
            case 0:
            case 1: grade = 'very weak'; break;
            case 2: grade = 'weak'; break;
            case 3: grade = 'strong'; break;
            default: grade = 'very strong'; break;
        }

        var redflag = false;
        if (pass.toLowerCase().indexOf('password') > -1)
            redflag = true;

        if (complex && long && !redflag) {
            return { Passed: true, Long: long, Complex: complex, ScoreOutOfFour: score, Grade: grade, Message: null };
        }
        else {
            var message;
            if (!complex && long)
                message = 'Password must have at least 3 of the following four characteristics: Uppercase Letters, Lowercase Letters, Special Characters, Numbers';
            else if (!long && complex)
                message = 'Password must be at least 10 characters long.';
            else if (redflag)
                message = 'Password should not contain words like "password"';
            else
                message = 'Password must be at least 10 characters long and meet complexity requirements.';

            return { Passed: false, Long: long, Complex: complex, ScoreOutOfFour: score, Grade: grade, Message: message };
        }
    };

    this.IsSpecialKeyEvent = function (event) {
        var key = event.which;
        var isSpecialKey = false;

        if (key == 20 /* Caps lock */
            || key == 16 /* Shift */
            || key == 9 /* Tab */
            || key == 27 /* Escape Key */
            || key == 17 /* Control Key */
            || key == 91 /* Windows Command Key */
            || key == 19 /* Pause Break */
            || key == 18 /* Alt Key */
            || key == 93 /* Right Click Point Key */
            || (key >= 35 && key <= 40) /* Home, End, Arrow Keys */
            || key == 45 /* Insert Key */
            || (key >= 33 && key <= 34) /*Page Down, Page Up */
            || (key >= 112 && key <= 123) /* F1 - F12 */
            || (key >= 144 && key <= 145)) { /* Num Lock, Scroll Lock */
            isSpecialKey = true;
        }

        if (event.altKey || event.ctrlKey || event.metaKey) {
            isSpecialKey = true;
        }

        return isSpecialKey;
    };

    this.ContainsNonNumeric = function (value) {
        var containsNonNumeric = false;
        var characterArray = value.split("");

        for (var i = 0; i < characterArray.length; i++) {
            var character = characterArray[i];

            if (!$.isNumeric(character)) {
                containsNonNumeric = true;
                break;
            }
        }

        return containsNonNumeric;
    };

    this.IsInteger = function (value) {
        return !isNaN(value) && (function (x) { return (x | 0) === x; })(parseFloat(value))
    };

    this.GetQueryStringParameter = function (name) {
        name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
        var regex = new RegExp("[\\?&]" + name + "=([^&#]*)");

        var parameter = regex.exec(location.search);
        if (!this.IsNullOrUndefined(parameter)) {
            parameter = decodeURIComponent(parameter[1].replace(/\+/g, " "));
        }

        return parameter;
    };

    this.ReportAjaxError = function (jqXHR, textStatus, errorThrown, apiActionOrEventName, fileName, details) {
        if ((typeof ErrorLogger !== "undefined") && !this.IsNullOrUndefined(ErrorLogger)) {
            var objErrorContext = ErrorLogger.EventContextModel('ajax: ' + apiActionOrEventName, ErrorLogger.EventTypes.Javascript);
            objErrorContext.ErrorCode = jqXHR.status;
            objErrorContext.FileName = fileName;
            objErrorContext.Details = 'errorThrown: ' + errorThrown + '\r\ntextStatus: ' + textStatus + '\r\n\r\n' + jqXHR.responseText;
            if (details)
                objErrorContext.Details = details + '\r\n' + objErrorContext.Details;
            ErrorLogger.ReportEvent(objErrorContext);
        }
    };

    this.SetupClient = function (siteBaseurl) {
        if (!Constants.ClientAddress) {
            $.ajax({
                url: siteBaseurl + 'Account/GetAddress',
                type: 'GET',
                success: function (replydata) {
                    window.Pompeii = window.Pompeii || {};
                    window.Pompeii.ClientAddress = replydata;
                }
            });
        }
    };

    this.PompeiiMessageTimer = null;
    this.ShowPompeiiMessageBox = function (message) {
        $("#PompeiiMessageText").html(message);
        $("#PompeiiMessageBox").css('margin-left', ($("#PompeiiMessageBox").outerWidth() / 2) * -1);
        $("#PompeiiMessageBox").fadeIn();
        this.PompeiiMessageTimer = setTimeout(this.HidePompeiiMessageBox, 3500);
    };
    this.HidePompeiiMessageBox = function () {
        window.clearTimeout(this.PompeiiMessageTimer);
        $("#PompeiiMessageBox").fadeOut();
    };

    this.Strcmp = function (str1, str2) {
        return (str1 < str2) ? -1 : (str1 > str2 ? 1 : 0);
    };

    this.ViewDocumentsForPrint = function (documentIds, accountId, options) {
        $("#overlayButton_Id").show();
        var Frame = document.createElement("iframe");
        Frame.style.visibility = "visible";
        Frame.style.position = "fixed";
        Frame.style.top = "0";
        Frame.style.left = "0";
        Frame.width = $(window).width();
        Frame.height = $(window).height();
        Frame.id = "printFrame";
        Frame.name = "printFrame";
        Frame.style.zIndex = "1099";

        var filterDocument = documentIds[0];

        if (options.PrintAll || filterDocument.length > 1) {
            for(var opt in options) {
                if (!opt) {
                   opt = false;
                }
            }
            Frame.src = api_baseurl + 'api/Print/DocumentsPDF?DocumentIds=' + filterDocument + '&AccountId=' + accountId + "&IncludeCharges=" + options.IncludeCharges + "&IncreaseHeaderSpace=" + options.IncreaseHeaderSpace + "&IncludeImages=" + options.IncludeImages + "&PrintAll=" + options.PrintAll + "&SortAscending=" + options.SortAscending + "&facilities=" + sessionStorage.userFacilities + "&rights=" + sessionStorage.userRights;
        }
        else {
            Frame.src = api_baseurl + 'api/Print/DocumentsPDF?DocumentIds=' + filterDocument + '&AccountId=' + accountId + "&IncludeCharges=" + options.IncludeCharges + "&IncreaseHeaderSpace=" + options.IncreaseHeaderSpace + "&IncludeImages=" + options.IncludeImages + "&PrintAll=" + options.PrintAll + "&SortAscending=" + options.SortAscending + "&facilities=" + sessionStorage.userFacilities + "&rights=" + sessionStorage.userRights;
        }

        document.body.appendChild(Frame);
    };

    this.ViewPOCDocumentForPrint = function (documentId, accountId, options) {
        // Why all the noise you might wonder? Well, it's simply because nothing else works.
        $("#overlayButton_Id").show();
        var Frame = document.createElement("iframe");
        Frame.style.visibility = "visible";
        Frame.style.position = "fixed";
        Frame.style.top = "0";
        Frame.style.left = "0";
        Frame.width = $(window).width();
        Frame.height = $(window).height();
        Frame.id = "printFrame";
        Frame.name = "printFrame";
        Frame.style.zIndex = "1099";
        Frame.src = api_baseurl + 'api/Print/PlanOfCarePDF?documentId=' + JSON.stringify(documentId) + '&accountId=' + JSON.stringify(accountId) + '&increaseHeaderSpace=' + JSON.stringify(options.IncreaseHeaderSpace) + '&IncludeImages=' + JSON.stringify(options.IncludeImages) + '&includePOCHistory=' + JSON.stringify(options.IncludePOCHistory) + "&facilities=" + sessionStorage.userFacilities + "&rights=" + sessionStorage.userRights;
        document.body.appendChild(Frame);
    };

    this.XhrRequest = function (params) {
        /// Parameters
        // Async, Cache, CodeFileForErrorLog, Data, Type, Url, XhrFunction

        var ajaxParameters = {
            async: params.Async,
            cache: params.Cache,
            contentType: "application/json",
            data: params.Data,
            traditional: true,
            type: params.Type,
            url: params.Url
        };

        if (this.IsNullOrUndefined(params.Async)) {
            ajaxParameters.async = true;
        }
        if (this.IsNullOrUndefined(params.Cache)) {
            ajaxParameters.cache = false;
        }
        if (this.IsNullOrUndefined(params.Data)) {
            ajaxParameters.data = {};
        }
        if (this.IsNullOrUndefined(params.Type)) {
            ajaxParameters.type = 'GET';
        }
        if (this.IsFunction(params.XhrFunction)) {
            ajaxParameters.xhr = params.XhrFunction;
        }

        return $.ajax(ajaxParameters)
            .fail(function (jqXHR, textStatus, errorThrown) {
                console.log(jqXHR + "|" + textStatus + "|" + errorThrown);
                if (!this.CheckAjaxErrorForLogout(jqXHR)) {
                    this.ReportAjaxError(jqXHR, textStatus, errorThrown, params.Url.replace(api_baseurl, ''), params.CodeFileForErrorLog);
                }
            });
    };
})();