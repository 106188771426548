export default {
    init: function () {
        this.browser = this.searchString(this.dataBrowser) || "An unknown browser";
        this.version = this.searchVersion(navigator.userAgent, this.versionSearchString) || this.searchVersion(navigator.appVersion, this.versionSearchString) || "an unknown version";
        this.OS = this.searchString(this.dataOS) || "an unknown OS";
        this.isMobile = this.OS == "iPad" || this.OS == "iPhone" || this.OS == "Android";
    },
    supportedForWeb: function () {
        var supported =
            this.browser == "Edge" ||
            (this.browser == "Chrome" && (this.version >= 46 || this.isMobile)) ||
            (this.browser == "Firefox" && this.version >= 40) ||
            (this.browser == "Safari" && (this.version >= 9 || this.isMobile));
        return supported;
    },
    supportedForWebAndIE: function () {
        var supported =
            this.browser == "Edge" ||
            (this.browser == "Explorer" && this.version >= 11) ||
            (this.browser == "Chrome" && (this.version >= 46 || this.isMobile)) ||
            (this.browser == "Firefox" && this.version >= 40) ||
            (this.browser == "Safari" && (this.version >= 9 || this.isMobile));
        return supported;
    },
    searchString: function (data) {
        for (var i = 0; i < data.length; i++) {
            var dataString = data[i].string;
            var dataProp = data[i].prop;
            this.versionSearchString = data[i].versionSearch || data[i].identity;
            if (dataString) {
                if (dataString.indexOf(data[i].subString) != -1) {
                    if (data[i].subString == "Trident") {
                        var tver = this.searchVersion(data[i].string, "Trident");
                        this.versionSearchString = (tver < 7.0) ? "MSIE" : "Trident";
                    }
                    return data[i].identity;
                }
            } else if (dataProp)
                return data[i].identity;
        }
    },
    searchVersion: function (dataString, searchString) {
        var index = dataString.indexOf(searchString);
        if (index == -1) return;
        var ver = parseFloat(dataString.substring(index + searchString.length + 1));
        if (searchString == "Trident" && ver == 7.0)
            return 11; //this is really IE11 in disguise
        return ver;
    },
    dataBrowser: [{
        string: navigator.userAgent,
        subString: "CriOS",
        versionSearch: "CriOS",
        identity: "Chrome" //on IOS only
    }, {
        string: navigator.userAgent,
        subString: "Edge",
        identity: "Edge"
    }, {
        string: navigator.userAgent,
        subString: "Trident",
        identity: "Explorer"
    }, {
        string: navigator.userAgent,
        subString: "Chrome",
        identity: "Chrome"
    }, {
        string: navigator.userAgent,
        subString: "OmniWeb",
        versionSearch: "OmniWeb/",
        identity: "OmniWeb"
    }, {
        string: navigator.vendor,
        subString: "Apple",
        identity: "Safari",
        versionSearch: "Version"
    }, {
        prop: window.opera,
        identity: "Opera",
        versionSearch: "Version"
    }, {
        string: navigator.vendor,
        subString: "iCab",
        identity: "iCab"
    }, {
        string: navigator.vendor,
        subString: "KDE",
        identity: "Konqueror"
    }, {
        string: navigator.userAgent,
        subString: "Firefox",
        identity: "Firefox"
    }, {
        string: navigator.vendor,
        subString: "Camino",
        identity: "Camino"
    }, { // for newer Netscapes (6+)
        string: navigator.userAgent,
        subString: "Netscape",
        identity: "Netscape"
    }, {
        string: navigator.userAgent,
        subString: "MSIE",
        identity: "Explorer",
        versionSearch: "MSIE"
    }, {
        string: navigator.userAgent,
        subString: "Gecko",
        identity: "Mozilla",
        versionSearch: "rv"
    }, { // for older Netscapes (4-)
        string: navigator.userAgent,
        subString: "Mozilla",
        identity: "Netscape",
        versionSearch: "Mozilla"
    }],
    dataOS: [{
        string: navigator.userAgent,
        subString: "Android",
        identity: "Android"
    }, {
        string: navigator.userAgent,
        subString: "iPad",
        identity: "iPad"
    }, {
        string: navigator.userAgent,
        subString: "iPhone",
        identity: "iPhone/iPod"
    }, {
        string: navigator.platform,
        subString: "Win",
        identity: "Windows"
    }, {
        string: navigator.platform,
        subString: "Mac",
        identity: "Mac"
    }, {
        string: navigator.userAgent,
        subString: "iPhone",
        identity: "iPhone/iPod"
    }, {
        string: navigator.platform,
        subString: "Linux",
        identity: "Linux"
    }]

};