export default {
    AuthenticateResult: {
        Unknown: 0,
        Success: 1,
        Fail: 2,
        ExpiredPassword: 3,
        AccountLocked: 4,
        CULExceeded: 5,
        Error: 6,
        UseSamlAuth: 7,
        //Access the enumerations Name, Value, and Description
        //AuthenticateResult.Properties[AuthenticateResult.AccountLocked].Name
        Properties: {
            0: { Value: 0, Name: "Unknown", Description: "Unknown" },
            1: { Value: 1, Name: "Success", Description: "Login successful" },
            2: { Value: 2, Name: "Fail", Description: "Login failed" },
            3: { Value: 3, Name: "Expired Password", Description: "Password is expired" },
            4: { Value: 4, Name: "Account Locked", Description: "Account is locked" },
            5: { Value: 5, Name: "CUL Exceeded", Description: "Too many concurrent users" },
            6: { Value: 6, Name: "Error", Description: "Error occurred" },
            7: { Value: 7, Name: "SAML Auth", Description: "Login using identity provider" },
        }
    },

    AuthenticateSAMLResult: {
        Unknown: 0,
        Success: 1,
        UserNotFound: 2,
        InvalidSecurityKey: 3,
        UnknownSystemCode: 4,
        CULExceeded: 5,
        ParseError: 6,
        Properties: {
          0: { Value: 0, Name: "Unknown", Description: "Unknown" },
          1: { Value: 1, Name: "Success", Description: "Login successful" },
          2: { Value: 2, Name: "UserNotFound", Description: "User not found" },
          3: { Value: 3, Name: "InvalidSecurityKey", Description: "Invalid security key" },
          4: { Value: 4, Name: "UnknownSystemCode", Description: "Unknown system code" },
          5: { Value: 5, Name: "CULExceeded", Description: "Too many concurrent users" },
          6: { Value: 6, Name: "ParseError", Description: "Error occurred" },
        }
    },

    ContainerStates: {
        Undocumented: 0,
        EntryMode: 1,
        Documented: 2,
        SectionHeader: 3,
        ReadOnly: 4,
        ViewPriorValues: 5
    },

    DIPStates: {
        Open: 0,
        Resolved: 1,
        Deleted: 2,
        Unresolved: 3,
        //Access the enumerations Name, Value, and Description
        //Pompeii.Enums.DIPStates.Properties[Pompeii.Enums.DIPStates.Open].Name
        Properties: {
            0: { Value: 0, Name: "Open", Description: "open interdisciplinary problem" },
            1: { Value: 1, Name: "Resolved", Description: "resolved interdisciplinary problem" },
            2: { Value: 2, Name: "Deleted", Description: "deleted" },
            3: { Value: 3, Name: "Unresolved", Description: "unresolved interdisciplinary problem" }
        }
    },

    DocumentPageTypes: {
        TemplateSelection: 1,
        AppointmentSelection: 2,
        AuthorizationSelection: 3,
        DocumentView: 4,
        DocumentEdit: 5
    },

    DocumentTypes: {
        New: "new",
        Edit: "edit",
        View: "view"
    },

    FrequencyTypes: {
        Hourly: 0,
        Daily: 1,
        Weekly: 2,
        PRN: 3,
        Interface: 4
    },

    GoalStates: {
        Active: 0,
        Met: 1,
        NotMet: 2,
        Discontinued: 3,
        //Access the enumerations Name, Value, and Description
        Properties: {
            0: { Value: 0, Name: "Active", Description: "Active" },
            1: { Value: 1, Name: "Met", Description: "Met" },
            2: { Value: 2, Name: "Not Met", Description: "Not Met" },
            3: { Value: 3, Name: "Discontinued", Description: "Discontinued" }
        }
    },

    HomeFrames: {
        Cards: "Cards",
        AppointmentSummary: "AppointmentSummary",
        PatientIrfPai: "PatientIrfpai"
    },

    HomeTabs: {
        DailySchedule: 0,
        MyOrders: 1,
        MyPatients: 2,
        FindPatients: 3,
        AllAppointments: 4,
        FindIRFPAIPatients: 5,
    },

    LoginState: {
        Login: 0,
        RecommendChangePass: 1,
        ChangePass: 2
    },

    MIPSState: {
        Possible: 0,
        Eligible: 1,
        Completed: 2
    },

    ModPatientTabs: {
        Documents: "Documents",
        Notifications: "Notifications",
        Authorizations: "Authorizations",
        Referrals: "Referrals",
        Appointments: "Appointments",
        PlanOfCare: "Plan of Care",
        IrfPai: "IRFPAI",
        Files: "FILES",
        Flowsheet: "FLOWSHEET",
        Orders: "ORDERS",
        MedicationsAndAllergies: "MED/ALGY"
    },

    OrderStates: {
        Active: 0,
        Complete: 1,
        Discontinued: 2,
        Deleted: 3,
        Expired: 4,
        Canceled: 5,
        //Access the enumerations Name, Value, and Description
        Properties: {
            0: { Value: 0, Name: "Active", Description: "active" },
            1: { Value: 1, Name: "Complete", Description: "complete" },
            2: { Value: 2, Name: "Discontinued", Description: "discontinued" },
            3: { Value: 3, Name: "Deleted", Description: "deleted" },
            4: { Value: 4, Name: "Expired", Description: "expired" },
            5: { Value: 5, Name: "Canceled", Description: "canceled" }
        }
    },

    PrintFaxPopupTypes: {
        SinglePrint: 0,
        SingleFax: 1,
        MultiPrint: 3,
        MultiFax: 4,
        POCPrint: 5
    },

    ProblemGoalType: {
        ShortTerm: 0,
        LongTerm: 1
    },

    ProblemInterventionStatus: {
        Active: 0,
        Completed: 1,
        Discontinued: 2,
        Canceled: 3,
        Properties: {
            0: { Value: 0, Name: "Active", Description: "active" },
            1: { Value: 1, Name: "Completed", Description: "completed" },
            2: { Value: 2, Name: "Discontinued", Description: "discontinued" },
            3: { Value: 3, Name: "Canceled", Description: "canceled" }
        }
    },

    ProgressReportType: {
        Visits: 0,
        Date: 1
    },

    ServiceLinkTypes: {
        Template: 0,
        Container: 1,
        BuildingBlock: 2
    },

    TemplateType: {
        None: 0,
        Discharge: 1,
        ReEval: 2,
        Eval: 3,
        NonContact: 4,
        IRFPAI: 5,
        TeamConference: 6
    }
}